// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../root/.nvm/versions/node/v21.6.2/lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../root/.nvm/versions/node/v21.6.2/lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../root/.nvm/versions/node/v21.6.2/lib/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Horizon-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roths {
    background : red;
}

.hehe {
    background : blue;
}

@font-face {
    font-family: 'Horizon';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Horizon', sans-serif;
  }`, "",{"version":3,"sources":["webpack://./App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,+DAAiE;IACjE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":[".roths {\n    background : red;\n}\n\n.hehe {\n    background : blue;\n}\n\n@font-face {\n    font-family: 'Horizon';\n    src: url('./assets/fonts/Horizon-Regular.otf') format('opentype');\n    font-weight: normal;\n    font-style: normal;\n  }\n\n  body {\n    font-family: 'Horizon', sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
